<template>
  <ion-page id="main-content">
    <ion-tabs class="">
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom" class="pt-2 ">
        <ion-tab-button tab="home" href="/home/dashboard">
          <ion-icon :icon="homeOutline"/>
          <ion-label>Home</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="documents" href="/documents/all">
          <ion-icon :icon="clipboardSharp"/>
          <ion-label>Inspections</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="components" href="/components/all">
          <ion-icon :icon="pricetagOutline"/>
          <ion-label>Components</ion-label>
        </ion-tab-button>
<!--        <ion-tab-button tab="cables" href="/cables/all">-->
<!--          <ion-icon :icon="flashOutline"/>-->
<!--          <ion-label>Cables</ion-label>-->
<!--        </ion-tab-button>-->
        <ion-tab-button tab="tasks" href="/tasks/all">
          <ion-icon :icon="checkmarkCircleOutline"/>
          <ion-label>Tasks</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="settings" href="/settings/main">
          <ion-icon :icon="appsOutline"/>
          <ion-label>More</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
    <ion-modal :is-open="isOpen">
      <ion-header>
        <ion-toolbar>
          <ion-title>
            <ion-icon :icon="search" color="primary" class="ion-margin-end"></ion-icon> Search
          </ion-title>
          <ion-buttons slot="end">
            <ion-button @click="setOpen(false)">Close</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        <documents-search-form v-on:close="isOpen = false"></documents-search-form>
      </ion-content>
    </ion-modal>
  </ion-page>
</template>

<script setup lang="ts">
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonIcon,
  IonPage,
  IonRouterOutlet,
  IonButton,
  IonModal,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonLabel,
} from '@ionic/vue';
import {
  fileTrayOutline,
  homeOutline,
  search,
    clipboard,
    pricetagOutline,
    appsOutline,
    checkmarkCircleOutline,
    clipboardSharp,
    flashOutline
} from 'ionicons/icons';

import {ref} from "vue";
import DocumentsSearchForm from "@/Components/document/DocumentsSearchForm.vue";
const isOpen = ref(false);
function setOpen(open: boolean) {
  isOpen.value = open;
}

</script>
