import axios from "axios";


class API {
  //  router;
    baseUrl;
    internalAxios;
    constructor(inpBaseURL: string) {
       // this.router = useRouter();
        this.baseUrl = inpBaseURL;
        this.internalAxios = axios.create({
             withCredentials: true,
            baseURL: this.baseUrl,
            headers: {
                Accept: "application/json",
            }
        });

        this.initialiseResponseInterceptors();

        this.initialiseRequestInterceptors();
    }

    initialiseResponseInterceptors() {
        this.internalAxios.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                // if (error.response.status === 401) {
                //     window.localStorage.removeItem('tka');
                //     window.location.pathname = '/login'
                // }
                return Promise.reject(error);
            }
        );
    }

    initialiseRequestInterceptors() {
        this.internalAxios.interceptors.request.use(
            (config) => {
                let token = window.localStorage.getItem("tka");
                const url = config.url
                if (url && (url.includes('component') || url.includes('cable'))) {
                    token = window.localStorage.getItem("c_tka");
                }
                config.headers.Authorization = `Bearer ${token}`;
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    }

    setHeaderCommons(headerName: string, headerValue: string) {
        if (this.internalAxios) {
            this.internalAxios.defaults.headers.common[headerName] = headerValue;
        }
    }

    get(endpoint: string, config = {}) {
        return this.internalAxios.get(endpoint, config);
    }

    patch(endpoint: string, data = {}, config = {}) {
        return this.internalAxios.patch(endpoint, data, config);
    }

    put(endpoint: string, data = {}, config = {}) {
        return this.internalAxios.put(endpoint, data, config);
    }

    post(endpoint: string, data = {}, config = {}) {
        return this.internalAxios.post(endpoint, data, config);
    }

    delete(endpoint: string, config = {}) {
        return this.internalAxios.delete(endpoint, config);
    }
}

export default API;

