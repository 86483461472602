import {defineStore} from "pinia";
import APIHelper from './../classes/APIHelper'

export const useCompStore = defineStore({
    id: "compStore",
    state: () => ({
        loading: false,
        showNotification: false,

        //Cables
        cables: [],
        selectedCable: {},
        cablesHaveLoaded: false,
        cableTerminations: [],
        cablesSearch: {},
        cablesSearchText: '',

        //rComponents
        rComponents: [],
        systems: [],
        rComponentsHaveLoaded: false,
        selectedRComponent: {id:null},
        selectedRComponentAllDetails: {id:null},
        rComponentsSearchForm: {},
        rComponentsSearchText: '',
        selectedRComponentDocuments: [],


        notification: {
            message: "Success!"
        }
    }),
    getters: {
        rComponentsSearchIsOn(state) {
            let keys;
            if (state.rComponentsSearchForm)
                keys = Object.keys(state.rComponentsSearchForm);
            return keys && keys.length > 0;
        },
    },
    actions: {
        setLoading(value: boolean) {
            this.loading = value;
        },
        setComponent(component: any) {
            this.$state.selectedRComponent = component
            this.fetchComponent(component.id)
        },
        clearSelectedCablesAllDetails(){
            if( this.$state.selectedRComponent && this.$state.selectedRComponentAllDetails &&  this.$state.selectedRComponent.id
                !==  this.$state.selectedRComponentAllDetails.id ){
                this.$state.selectedRComponentAllDetails = {id:null}
            }

        },
        setComponentHasForm() {
            this.$state.rComponentsSearchForm = true
        },
        searchRComponents(search: any) {
            this.$state.rComponentsSearchForm = search
        },
        fetchComponents() {
            this.$state.rComponents = []
            this.setLoading(true)
            this.$state.rComponentsHaveLoaded = false
            let search = null;
            if (this.rComponentsSearchIsOn) {
                search = {
                    ...this.rComponentsSearchForm
                }
            }
            search = JSON.stringify(this.rComponentsSearchForm);
            console.log('fetchComponents search', search)
            APIHelper.CompAPI.get('/data-rows', {search}).then((resp: any) => {
                this.$state.rComponents = resp.data.components
                this.$state.systems = resp.data.systems
                this.$state.loading = false;
                this.$state.rComponentsHaveLoaded = true
            }).catch((err: any) => {
                console.log(err)
            })
        },
        fetchComponent(id :number) {
            this.$state.selectedRComponentAllDetails = {id:null}
            this.setLoading(true)
            APIHelper.DocsAPI.get(`/data-rows/${id}`).then((resp: any) => {
                console.log('fetchComponent',resp.data)
                //this.$state.selectedRComponentAllDetails = resp.data
                this.$state.loading = false;
            }).catch((err: any) => {
                console.log(err)
            })
        },
    }
});
