import {createRouter, createWebHistory} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';

import DocumentsLayout from '../layouts/DocumentsLayout.vue'
import MainAppLayout from '../layouts/MainAppLayout.vue'
import DocumentLayout from '../layouts/DocumentLayout.vue'
import LoginUser from "../views/auth/LoginUser.vue";
import homePage from '../views/HomePage.vue'
import ProjectsPage from "@/views/projects/ProjectsPage.vue";

//COMPONENTS
// import ComponentsLayout from "@/layouts/ComponentsLayout.vue";
const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/home/dashboard'
    },
    {
        path: "/login",
        name: "login",
        component: LoginUser
    },
    {
        path: "/projects",
        name: "projects",
        component: ProjectsPage
    },
    /* ------------------------------
        Home / Main Dashboard
     ------------------------------*/
    {
        path: "/home",
        component: MainAppLayout,
        children: [
            {
                path: 'dashboard',
                name: "home.dashboard",
                component: homePage
            },
        ]
    },

    /* ------------------------------
     Documents
    ------------------------------*/
    // {
    //     path: '/documents/list',
    //     name:'documents',
    //     props:true,
    //     component: () => import('@/views/documents/DocumentsPage.vue')
    // },
    {
        path: '/documents/',
        component: MainAppLayout,
        children: [
            {
                path: 'all',
                name: "documents.all",
                component: () => import('@/views/documents/DocumentsPage.vue')
            },
            {
                path: 'home',
                name: "documents.stats",
                component: () => import('@/views/documents/DocumentsStats.vue')
            },
        ]
    },
    {
        path: '/document/:id',
        component: MainAppLayout,
        children: [
            {
                path: 'details',
                component: () => import('@/Components/document/DocumentDetails.vue')
            },
            // {
            //     path: 'execute',
            //     component: () => import('@/Components/document/Z_DocumentExecuteOLD.vue')
            // },
        ],
    },
    {
        path: '/document/:id/execute',
        component: () => import('@/Components/Documents/Execute/DocumentExecute.vue')
    },
    {
        path: '/document/:id/view',
        component: () => import('@/Components/document/DocumentView.vue')
    },
    /* ------------------------------
        RowSys Components
     ------------------------------*/
    {
        path: '/components/',
        component: MainAppLayout,
        children: [
            {
                path: 'all',
                name: "components.all",
                component: () => import('@/views/components/RComponentsList.vue')
            },
        ]
    },
    {
        path: '/component/:id',
        name:'Component.detail',
        props:true,
        component: () => import('@/views/components/RComponent.vue')
    },
    /* ------------------------------
        Tasks
     ------------------------------*/
    {
        path: '/tasks/',
        component: MainAppLayout,
        children: [
            {
                path: 'all',
                name: "tasks.all",
                component: () => import('@/views/tasks/TasksList.vue')
            },
        ]
    },
    /* ------------------------------
        Settings
     ------------------------------*/
    {
        path: '/settings/',
        component: MainAppLayout,
        children: [
            {
                path: 'main',
                name: "settings.main",
                component: () => import('@/views/settings/SettingsMain.vue')
            },
        ]
    },
    /* ------------------------------
     Cables
    ------------------------------*/
    {
        path: '/cables',
        name: "cables",
        component: () => import('@/views/cables/CablesList.vue')
    },
    {
        path: '/cable/:id',
        name:'cable',
        props:true,
        component: () => import('@/views/cables/CableDetails.vue')
    },

    // {
    //     path: '/tabs/',
    //     component: TabsPage,
    //     children: [
    //         {
    //             path: 'tab1',
    //             name: "tab1",
    //             component: () => import('@/views/Tab1Page.vue')
    //         },
    //         {
    //             path: 'tab2',
    //             component: () => import('@/views/Tab2Page.vue')
    //         },
    //         {
    //             path: 'tab3',
    //             component: () => import('@/views/Tab3Page.vue')
    //         },
    //         {
    //             path: 'tab4',
    //             component: () => import('@/views/Tab4Page.vue')
    //         }
    //     ]
    // }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

const isAuthenticated = () => {
    return  window.localStorage.getItem("tka") ;
    //return token && token !== ''
}

// function logoutUser(){
//
// }


router.beforeEach((to) => {

    // console.log('to',to , 'isAuthenticated ' ,isAuthenticated())
    if (to.name !== 'login'  && !isAuthenticated()) {
        return {name: 'login'}
    }
    //
    // if (to.name === 'login' && isAuthenticated()) {
    //     return { name: 'home' }
    // }
    //
    // if (to.name !== 'login' && !isAuthenticated()) {
    //     return {name: 'login'}
    // }

    // if (!isAuthenticated() && to.name !== 'Login') {
    //     return { name: 'login' }
    // }
});

export default router
