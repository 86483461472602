<template>
  <form>
    <div class="mb-3">
      <label for="search_name" class="block mb-2 font-medium text-gray-900 dark:text-white">Search Text</label>
      <input v-model="documentSearchForm.text" type="text" id="search_name"
             class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
    </div>
    <!--    <div class="mb-3">-->
    <!--      <label for="search_number" class="block mb-2 font-medium text-gray-900 dark:text-white">Number</label>-->
    <!--      <input  v-model="documentSearchForm.number" type="text" id="search_number" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" >-->
    <!--    </div>-->
    <!--    <div class="mb-3">-->
    <!--      <label for="search_description" class="block mb-2 font-medium text-gray-900 dark:text-white">Description</label>-->
    <!--      <input v-model="documentSearchForm.description"  type="text" id="search_description" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">-->
    <!--    </div>-->

    <div class="relative z-0 w-full mb-4">
    <ion-item>
      <ion-label position="stacked">Search Text</ion-label>
      <ion-input></ion-input>
    </ion-item>
    </div>


    <div class="relative z-0 w-full mb-4">
      <!--      <label for="status" class="block mb-2 font-medium text-gray-900 dark:text-white">Templates</label>-->
      <!--      <select id="status"-->
      <!--              class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">-->
      <!--        <option></option>-->
      <!--        <option>Template 02</option>-->
      <!--      </select>-->


      <!--        <div>-->
      <!--          <label for="status" class="block mb-2 font-medium text-gray-900 dark:text-white">Templates</label>-->
      <!--        </div>-->
      <ion-item>
        <ion-select placeholder="Choose a Template" interface="action-sheet">
          <ion-select-option value="apples">Apples</ion-select-option>
          <ion-select-option value="oranges">Oranges</ion-select-option>
          <ion-select-option value="bananas">Bananas</ion-select-option>
        </ion-select>
      </ion-item>

    </div>

    <div class="relative z-0 w-full mb-6 group">
      <label for="status" class="block mb-2 font-medium text-gray-900 dark:text-white">Component Types</label>
      <select id="status"
              class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
        <option></option>
        <option>Motor</option>
      </select>
    </div>

    <div class="relative z-0 w-full mb-6 group">
      <label for="status" class="block mb-2 font-medium text-gray-900 dark:text-white">Status</label>
      <select v-model="documentSearchForm.status" id="status"
              class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
        <option></option>
        <option value="10">Not Started</option>
        <option value="20">In Progress</option>
        <option value="100">Complete</option>
      </select>
    </div>
    <div class="relative z-0 w-full mb-6 group">
      <label for="owners" class="block mb-2 font-medium text-gray-900 dark:text-white">User</label>
      <select v-model="documentSearchForm.user" id="owners"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
        <option></option>
        <option value="assignedToMe">Assigned to Me</option>
        <option value="createdByMe">Created by Me</option>
      </select>
    </div>

    <div class="relative z-0 w-full mb-6 group">
      <label for="status" class="block mb-2 font-medium text-gray-900 dark:text-white">Assigned Team</label>
      <select v-model="documentSearchForm.assignedTeam" id="status"
              class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
        <option v-for="squad in squads" :key="squad.id">{{ squad.name }}</option>
      </select>
    </div>
    <!--          <div class="relative z-0 w-full mb-6 group">-->
    <!--            <input type="text" name="search_name" id="search_name"-->
    <!--                   class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"/>-->
    <!--            <label for="search_name"-->
    <!--                   class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Name</label>-->
    <!--          </div>-->
    <!--          <div class="relative z-0 w-full mb-6 group">-->
    <!--            <input type="text" name="search_description" id="search_description"-->
    <!--                   class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"/>-->
    <!--            <label for="search_description"-->
    <!--                   class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Description</label>-->
    <!--          </div>-->
    <!--    <div class="relative z-0 w-full mb-6 group">-->
    <!--      <label for="template" class="block mb-2 font-medium text-gray-900 dark:text-white">Template</label>-->
    <!--      <select v-model="documentSearchForm.template" id="template"-->
    <!--              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">-->
    <!--        <option>Temaplte 01</option>-->
    <!--        <option>Canada</option>-->
    <!--        <option>France</option>-->
    <!--        <option>Germany</option>-->
    <!--      </select>-->
    <!--    </div>-->
    <ion-button @click="setSearchAndGetDocuments" class="mb-4" expand="block">
      <ion-icon :icon="search" class="ion-margin-end"></ion-icon>
      Search
    </ion-button>

    <div v-if="documentSearchIsOn">
      <ion-button @click="resetSearchAndGetDocuments" fill="outline" expand="block">
        <ion-icon :icon="refreshOutline" color="primary" class="ion-margin-end"></ion-icon>
        Reset
      </ion-button>
    </div>
  </form>

</template>

<script setup lang="ts">
import {
  IonButton, IonIcon, IonLabel,
  IonItem, IonList, IonSelect, IonSelectOption, IonInput
} from '@ionic/vue';
import {search, refreshOutline} from 'ionicons/icons';
import {useRouter} from 'vue-router';
import {useMainStore} from '@/stores/mainStore'
import {useAuthStore} from '@/stores/authStore'

const mainStore = useMainStore()
const authStore = useAuthStore()
import {computed, defineEmits, nextTick} from "vue";

const emit = defineEmits(['close'])
const documentSearchForm = computed(() => {
  return mainStore.documentSearchForm;
})
const router = useRouter();

function setSearchAndGetDocuments() {
  emit('close')
  mainStore.searchDocuments(documentSearchForm.value)
  nextTick(() => {
    mainStore.fetchDocuments()
    router.push(`/documents/all`)
  })
}

const documentSearchIsOn = computed(() => {
  return mainStore.documentSearchIsOn
})
const squads = computed(() => {
  return authStore.squads
})

function resetSearchAndGetDocuments() {
  emit('close')
  mainStore.searchDocuments({})
  nextTick(() => {
    mainStore.fetchDocuments()
    router.push(`/documents/all`)
  })
}
</script>

<style scoped>

</style>