import APIHelper from '@/classes/APIHelper';
import {defineStore} from 'pinia'
export const useAuthStore = defineStore({
    id: 'authStore',
    state: () => ({
        user:{
            id:null,
            name:'loading...'
        },
        squads:null
    }),
    getters: {
    },
    actions: {
        async fetchUser() {
            if(!this.user || !this.user.id ){
                try {
                    this.user  = await APIHelper.AuthAPI.get("/user").then((resp:any)=>{
                        return resp.data.data
                    })
                } catch (error) {
                    return error
                }
            }
        },
        async fetchSquads() {
            if(!this.squads){
                try {
                    this.squads  = await APIHelper.AuthAPI.get("/squads").then((resp:any)=>{
                        return resp.data
                    })
                } catch (error) {
                    return error
                }
            }
        }
    }
})

export default useAuthStore;
