import API from './API';
 // import * as process from "process";

// const domainForCallback = window ? window.location.origin : '';
// const process = { env: import.meta.env };
// const DEBUG_MODE_VALIDATE = 'local'; // process.env.VUE_APP_DEBUG_MODE_VALIDATE && process.env.VUE_APP_ENV !== 'production';
//
// const LMSUrl = import.meta.env.VITE_APP_API_URL_LMS;
// const CommsUrl = import.meta.env.VITE_APP_API_URL_COMMS;
const AuthUrl = process.env.VUE_APP_API_URL_DOCUMENTS;// 'http://localhost:8101/api'; // 'https://docs.rowsys.com/api'; // process.env.VITE_APP_API_URL_DOCS; //'http://localhost:8101/api'; //
const DocsUrl = process.env.VUE_APP_API_URL_DOCUMENTS;//process.env.VUE_APP_API_URL_DOCS; // 'https://docs.rowsys.com/api';//  process.env.VITE_APP_API_URL_DOCS;//'http://localhost:8101/api'; //
const ComponentsUrl = process.env.VUE_APP_API_URL_DOCUMENTS;
class APIHelper {
  AppAPIs:any = [];
  AuthAPI = new API(AuthUrl);
  DocsAPI = new API(DocsUrl);
  CompAPI = new API(ComponentsUrl);
  constructor() {
    this.AppAPIs.push(this.AuthAPI);
    this.AppAPIs.push(this.DocsAPI);
    this.AppAPIs.push(this.CompAPI);
    if (this.isUserLoggedIn()) {
      this.AppAPIs.forEach((itApi:any) => {
        itApi.setHeaderCommons(
          'Authorization',
          'Bearer ' + localStorage.getItem('tka')
        );
      });
    }
  }

  isUserLoggedIn() {
    return (
      localStorage.getItem('tka') !== null &&
      localStorage.getItem('tkr') !== null
    );
  }

  handleLogin(req:any) {
    // Store intended route & redirect to auth route
    const intRoute = req.fullPath;
    // if (req.query.hasOwnProperty('intendedroute')) {
    //   intRoute = req.query.intendedroute;
    // }
    localStorage.setItem('intended_route', intRoute);
    // window.location.href = this.oauthObj?.code.getUri() ?? '';
  }

  async handleLogout() {
     await this.AuthAPI?.post('/logout');
    localStorage.removeItem('userBasics');
    sessionStorage.removeItem('tka');
    sessionStorage.removeItem('tkr');
    sessionStorage.removeItem('intended_route');
    this.AppAPIs.forEach((itApi:any) => {
      itApi.setHeaderCommons('Authorization', null);
    });
  }
}

export default new APIHelper();
