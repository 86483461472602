import {defineStore} from "pinia";
import APIHelper from './../classes/APIHelper'

export const useMainStore = defineStore({
    id: "mainStore",
    state: () => ({
        loading: false,
        showNotification: false,
        projects: [],
        documents: [],
        phases: [],
        selectedProject: {},
        documentSearchForm: {},
        documentStats: {},
        document: {},
        documentStatistics: {},
        approvedDocumentUrl: '',
        documentHasForm: false,
        documentsLoaded: false,
        documentViewType: 'documentView',
        approvedDocumentHtmlPartsInJson: {},
        documentDetails: {},
        documentData: {},
        notification: {
            message: "Success!"
        }
    }),
    getters: {
        documentSearchIsOn(state) {
            let keys;
            if (state.documentSearchForm)
                keys = Object.keys(state.documentSearchForm);
            if (keys && keys.length > 0) {
                return true;
            }
            return false
        },
        // documentData(state) {
        //   if(state.documentDetails && state.documentDetails.data )
        //  return state.documentDetails.data
        // },
    },
    actions: {
        setLoading(value: boolean) {
            this.loading = value;
        },
        setDocument(document: any) {
            this.$state.document = document
        },
        setDocumentHasForm() {
            this.$state.documentHasForm = true
        },
        searchDocuments(search: any) {
            this.$state.documentSearchForm = search
        },
        fetchDocuments() {
            this.$state.documents = []
            this.setLoading(true)
            this.$state.documentsLoaded = false
            let search = null;
            if (this.documentSearchIsOn) {
                // search = {
                //     ...this.documentSearchForm
                // }
            }
            search = JSON.stringify(this.documentSearchForm);
            console.log('SEARCH', this.documentSearchForm)
            APIHelper.DocsAPI.post('/all-documents', this.documentSearchForm).then((resp: any) => {
                this.$state.documents = resp.data.documents
                this.$state.loading = false;
                this.$state.documentStats = resp.data.stats
                this.$state.documentsLoaded = true
            }).catch((err: any) => {
                console.log(err)
            })
        },
        fetchDocumentSections(id: any) {
            this.$state.documentDetails = {}
            this.$state.documentViewType = 'documentView';
            this.setLoading(true)
            APIHelper.DocsAPI.get('/document/sections/' + id).then((resp: any) => {
                const data = resp.data;
                data['documentId'] = id; //need the document id for linked documents
                this.$state.documentDetails = data
                this.$state.documentData = resp.data.data
                this.$state.loading = false;
            }).catch((err: any) => {
                console.log(err)
            })
        },
        fetchDocument(id: any) {
            this.$state.document = {}
            this.$state.documentViewType = 'documentView';
            this.$state.approvedDocumentUrl = '';
            this.$state.approvedDocumentHtmlPartsInJson = {};
            this.setLoading(true)
            APIHelper.DocsAPI.get('/document/document/' + id + '?html=true').then((resp: any) => {
                this.$state.document = resp.data.data
                this.$state.approvedDocumentUrl = resp.data.documentUrl;
                this.$state.approvedDocumentHtmlPartsInJson = JSON.parse(resp.data.documentHtmlParts)
                this.$state.loading = false;
            }).catch((err: any) => {
                console.log(err)
            })
        },
        updateFormInput(details: any) {
            const data = {
                guid: details.guid,
                formId: details.formId,
                docId: details.docId,
                data: details.data,
                note: details.details.note
            }
            APIHelper.DocsAPI.post('/template/form-input', data).then((resp: any) => {
                console.log(resp)
            }).catch((err: any) => {
                console.log(err)
            })
        },
        updateSectionData(details: any) {
            APIHelper.DocsAPI.put('/document/section-data/' + details.id, {
                data: details.data
            }).then((resp: any) => {
                console.log(resp)
            }).catch((err: any) => {
                console.log(err)
            })
        },
        async approveDocument(id: any) {
            this.setLoading(true)
            return APIHelper.DocsAPI.post('/document/approve/' + id).then((resp: any) => {
                this.$state.loading = false;
                return resp.data
            });
        },
        async moveDocumentPhase(id: any) {
            this.setLoading(true)
            return APIHelper.DocsAPI.put('/document/move-phase/' + id).then((resp: any) => {
                this.$state.loading = false;
                return resp.data
            });
        },
        async setAssignee(documentId: number, userId: number) {
            this.$state.loading = true;
            return APIHelper.DocsAPI.post('/document/set-assignee/', {
                document: documentId,
                user: userId
            }).then((resp: any) => {
                this.$state.loading = false;
                return resp.data
            });
        },
        setDocumentViewType(type: string) {
            this.$state.documentViewType = type;
        },
        fetchDocsStats() {
            this.setLoading(true)
            APIHelper.DocsAPI.get('/document-stats').then((resp: any) => {
                this.$state.documentStatistics = resp.data.data
                this.$state.loading = false;
            }).catch((err: any) => {
                console.log(err)
            })
        },
        async switchProject(id: number) {
            this.$state.loading = true;
            return APIHelper.DocsAPI.post('/admin/switch-project/', {
                project: id
            }).then((resp: any) => {
                this.$state.loading = false;
                return resp.data
            });
        },
        async createOrGetLinkedDocument(details: any) {
            console.log('createLinkedDocument', details)
            this.$state.loading = true;
            return APIHelper.DocsAPI.post('/document/linked-document', details)
                .then((resp: any) => {
                    this.$state.loading = false;
                    console.log('createOrGetLinkedDocument', resp.data)
                    return resp.data
                }).catch((err: any) => {
                    console.log(err)
                });
        },
        async fetchPhases() {
            this.$state.loading = true;
            return APIHelper.DocsAPI.get('/document-phases')
                .then((resp: any) => {
                    this.$state.loading = false;
                    this.$state.phases = resp.data.data
                }).catch((err: any) => {
                    console.log(err)
                });
        }
        // showNotificationMessage(message, type = "success") {
        //   this.showNotification = true;
        //   this.notification.message = message;
        //   setTimeout(() => {
        //     this.showNotification = false;
        //     this.notification.message = "Success!";
        //   }, 2000);
        // }
    }
});
