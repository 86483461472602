<template>
  <ion-page>

    <ion-header>
      <ion-toolbar>
        <ion-title>Home</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content class="">

      <div v-if="user" class="">
        <ion-card class="bg-white">
          <ion-card-header>
            <ion-card-title>Hi, {{ user.name }}</ion-card-title>
            <ion-card-subtitle class="lowercase">{{ user.email }}</ion-card-subtitle>
          </ion-card-header>
          <ion-card-content>
            <div class="flex">
              <div class="mr-1 pt-0.5">
                <ion-icon :icon="checkmarkCircleOutline"/>
              </div>
              <div v-if="user.currentProject">{{ user.currentProject.name }}</div>
            </div>
          </ion-card-content>
        </ion-card>
      </div>

      <div class="flex justify-between">
        <div class="font-bold text-lg ml-3">Inspections</div>
        <div class="pr-5 text-gray-500 text-sm">View All</div>
      </div>

      <div class="flex m-3">
        <div class="border w-56 bg-gray-100 mr-2 shadow-lg p-2 rounded-lg">
          Total
          <div v-if="dashboardData">{{ dashboardData?.totalCount }}</div>
        </div>
        <div class="border w-56 bg-orange-200 shadow-lg p-2 mr-2 rounded-lg">
          In Progress
          <div v-if="dashboardData">{{ dashboardData?.inProgressCount }}</div>
        </div>
        <div class="border w-56 bg-green-200 shadow-lg p-2 rounded-lg">
          Complete
          <div v-if="dashboardData">{{ dashboardData?.completedCount }}</div>
        </div>
      </div>

      <div v-if="dashboardData && dashboardData.myDocs.length > 0">
        <div class="flex justify-between">
          <div class="font-bold text-lg ml-3">My Inspections</div>
          <div class="pr-5 text-gray-500 text-sm">View All</div>
        </div>
        <div class="" style="overflow-y: scroll">
          <div class="flex ">
            <div class=" mr-4" v-for="item in dashboardData.myDocs" :key="item.id" @click="goToDocument(item)">
              <div class="" style="width:330px;">
                <ion-card class="bg-white">
                  <ion-card-content  style="height: 150px">
                    <div class="text-blue-600 text-sm mb-1">{{ item.template.name }}</div>
                    <div class="font-bold mb-1 text-black">{{ item.name }}</div>
                    <div class="mb-1">{{ item.description }}</div>
                    <div class="mb-1">
                      <span   :class="{
                        'bg-green-300 text-green-700': item.status === 'complete',
                          'bg-gray-300 text-gray-600': item.status === 'ready',
                            'bg-orange-300 text-white': item.status === 'started'
                      }"
                          class=" text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                        {{ item.status }}</span>
                    </div>
                  </ion-card-content>
                </ion-card>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="dashboardData && dashboardData.started.length > 0">
        <div class="flex justify-between">
          <div class="font-bold text-lg ml-3">In Progress</div>
          <div class="pr-5 text-gray-500 text-sm">View All</div>
        </div>
        <div class="" style="overflow-y: scroll; -ms-overflow-style: none;">
          <div class="flex ">
            <div class=" mr-4" v-for="item in dashboardData.started" :key="item.id" @click="goToDocument(item)">
              <div style="width:330px;">
                <ion-card class="bg-white">
                  <ion-card-content style="height: 150px">
                    <div class="text-blue-600 text-sm mb-1">{{ item.template.name }}</div>
                    <div class="font-bold mb-1 text-black">{{ item.name }}</div>
                    <div class="mb-1">{{ item.description }}</div>
                    <div class="mb-1">
                      <span   :class="{
                        'bg-green-300 text-green-700': item.status === 'complete',
                          'bg-gray-300 text-gray-600': item.status === 'ready',
                            'bg-orange-300 text-white': item.status === 'started'
                      }"
                              class=" text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                        {{ item.status }}</span>
                    </div>
                  </ion-card-content>
                </ion-card>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="dashboardData && dashboardData.upNext.length > 0 ">
        <div class="flex justify-between">
          <div class="font-bold text-lg ml-3">Up Next</div>
          <div class="pr-5 text-sm">View All</div>
        </div>
        <div class="" style="overflow-y: scroll; -ms-overflow-style: none;">
          <div class="flex ">
            <div class=" mr-4" v-for="item in dashboardData.upNext" :key="item.id" @click="goToDocument(item)">
              <div style="width:330px;">
                <ion-card class="bg-white">
                  <ion-card-content style="height: 150px">
                    <div class="text-blue-600 text-sm mb-1">{{ item.template.name }}</div>
                    <div class="font-bold mb-1 text-black">{{ item.name }}</div>
                    <div class="mb-1">{{ item.description }}</div>
                    <div class="mb-1">
                      <span   :class="{
                        'bg-green-300 text-green-700': item.status === 'complete',
                          'bg-gray-200 text-gray-600': item.status === 'ready',
                            'bg-orange-300 text-white': item.status === 'started'
                      }"
                              class=" text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                        {{ item.status }}</span>
                    </div>
                  </ion-card-content>
                </ion-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import {useRouter} from "vue-router";
import {playCircle, radio, library, search} from 'ionicons/icons';

const router = useRouter();
import {
  IonIcon,
  IonPage,
  IonHeader,
  IonButton,
  IonToolbar,
  IonFooter,
  IonTitle,
  IonContent,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonLabel
} from '@ionic/vue';
import {
  businessOutline,
  cubeOutline,
  documentOutline,
  flashOutline,
  shapesOutline,
  pricetagOutline,
  atCircleOutline,
  checkmarkCircleOutline
} from 'ionicons/icons';
import {ref, computed, nextTick, markRaw, onMounted} from "vue";
import {useCompStore} from '@/stores/compStore'
import {useAuthStore} from '@/stores/authStore'
import {useCableStore} from "@/stores/cablesStore";
import APIHelper from "@/classes/APIHelper";
import {useMainStore} from "@/stores/mainStore";

const authStore = useAuthStore()
const compStore = useCompStore()
const mainStore = useMainStore()
//Store
const user = computed(() => {
  return authStore.user
})

function gotoDocuments() {
  router.push({name: 'documents'})
}

function gotoCables() {
  router.push({name: 'cables'})
}

function gotoComponents() {
  router.push({name: 'components'})
}

function gotoProjects() {
  router.push({name: 'projects'})
}

//Documents
const dashboardData = ref(null)

async function fetchDashboardData() {
  APIHelper.DocsAPI.get('/dashboard').then((resp: any) => {
    console.log('fetchDashboardData', resp.data)
    dashboardData.value = resp.data
  }).catch((err: any) => {
    console.log(err)
  })

}
function goToDocument(document: any) {

  mainStore.setLoading(true)
  if (document.status === 'Complete') {
    nextTick(() => {
      router.push(`/document/${document.id}/view`)
    })
  } else {
    nextTick(() => {
      router.push(`/document/${document.id}/execute`)
    })
  }
}
onMounted(() => {
  fetchDashboardData();
  authStore.fetchUser();
})
</script>

<style>
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */

</style>
